import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import { IPostImagesArgs, IPostImagesInput } from "./images.types"

export const useMutationPostImage = (args?: IPostImagesArgs) =>
  useMutation<AxiosResponse<{ url: string }>, AxiosError, IPostImagesInput>(
    async ({ upload }) => {
      const formData = new FormData()
      formData.append("upload", upload)

      return await axiosInstance.post(Endpoint.IMAGES, formData)
    },
    args?.options
  )
