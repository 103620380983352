import React from "react"

import { ReactComponent as SvgStudentDashboard } from "@brand_assets/svg/student-dashboard.svg"
import { Box, useMediaQuery } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import { selectUser } from "store/auth/auth.selectors"
import { isStudent } from "utils/roleCheck"

const StudentImages = () => {
  const isTablet = useIsBreakpointUp("tablet")
  const isDesktop = useMediaQuery("(min-width: 1440px)")
  const userInfo = useAppSelector(selectUser)
  const hasSavingAccount =
    userInfo && isStudent(userInfo.user) && userInfo.user.has_savings_accounts

  return (
    <Box width="100%" height="100%" borderRadius="16px">
      {isTablet && (
        <Box
          sx={
            process.env.REACT_APP_TYPE === "classequity"
              ? {
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: 2,
                  position: "absolute",
                  top: "-130px",
                  right: "30px",
                  zIndex: 0,
                }
              : {
                  // only for Bankee
                  position: "absolute",
                  top: "50%",
                  right: "20px",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                }
          }
        >
          {isDesktop && !hasSavingAccount && (
            <SvgStudentDashboard width="200px" height="200px" />
          )}
        </Box>
      )}
    </Box>
  )
}

export default StudentImages
