import svgTeacherDashboardBottom from "@brand_assets/svg/teacher-dashboard-bottom.svg"
import svgTeacherDashboardMobile from "@brand_assets/svg/teacher-dashboard-mobile.svg"
import { ReactComponent as SvgTeacherDashboardTop } from "@brand_assets/svg/teacher-dashboard-top.svg"
import { Box } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"

import { StyledImg } from "./TeacherImages.styles"

const TeacherImages = () => {
  const isTablet = useIsBreakpointUp("tablet")

  return isTablet ? (
    <Box>
      <Box position="absolute" top="-26px" right="0" zIndex={2}>
        <SvgTeacherDashboardTop />
      </Box>
      <StyledImg
        src={svgTeacherDashboardBottom}
        alt="background-photo-welcome"
      />
    </Box>
  ) : (
    <StyledImg src={svgTeacherDashboardMobile} alt="background-photo-welcome" />
  )
}

export default TeacherImages
